import React from 'react';
import { BrowserRouter, Routes, Route, } from "react-router-dom";
import HomePage4 from '../HomePage4/HomePage4';
import AboutPage from '../AboutPage/AboutPage';
import ServicePage from '../ServicePage/ServicePage';
import ServiceSinglePage from '../ServiceSinglePage/ServiceSinglePage';
import TeamSinglePage from '../TeamSinglePage/TeamSinglePage';
import BlogPage from '../BlogPage/BlogPage'
import BlogPageLeft from '../BlogPageLeft/BlogPageLeft'
import BlogPageFullwidth from '../BlogPageFullwidth/BlogPageFullwidth'
import BlogDetails from '../BlogDetails/BlogDetails'
import BlogDetailsLeftSiide from '../BlogDetailsLeftSiide/BlogDetailsLeftSiide'
import BlogDetailsFull from '../BlogDetailsFull/BlogDetailsFull'
import ContactPage from '../ContactPage/ContactPage';
import ErrorPage from '../ErrorPage/ErrorPage';

import CsrSinglePage from '../CsrSinglePage/CsrSinglePage';
import CsrPage from '../CsrPage/CsrPage';
import EventPage from '../EventPage/EventPage';
import EventSinglePage from '../EventSinglePage/EventSinglePage';
import CareerPage from '../Career/Career';
import CareerDetails from '../CareersDetails/CareerDetails';
import StudentReq from '../Career/StudentReq';
import Secretary from '../Career/Secretary';


const AllRoute = () => {

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomePage4 />} />
          <Route path="/home" element={<HomePage4 />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/career" element={<CareerDetails />} />
          <Route path="/career/Project-Management" element={<CareerPage />} />
          <Route path="/career/Student-Recruitment" element={<StudentReq />} />
          <Route path="/career/Secretary" element={<Secretary />} />
          <Route path="/service" element={<ServicePage />} />
          <Route path="/events" element={<EventPage />} />
          <Route path="/service/:slug" element={<ServiceSinglePage />} />
          <Route path="/events/:slug" element={<EventSinglePage />} />
          <Route path="/team-single/:slug" element={<TeamSinglePage />} />
          <Route path='/blog' element={<BlogPage />} />
          <Route path='/blog-left-sidebar' element={<BlogPageLeft />} />
          <Route path='/blog-fullwidth' element={<BlogPageFullwidth />} />
          <Route path='/blog-single/:slug' element={<BlogDetails />} />
          <Route path='/blog-single-left-sidebar/:slug' element={<BlogDetailsLeftSiide />} />
          <Route path='/blog-single-fullwidth/:slug' element={<BlogDetailsFull />} />
          <Route path='/contact' element={<ContactPage />} />
          <Route path='/csr/:slug' element={<CsrSinglePage />} />
          <Route path='/event/:slug' element={<EventSinglePage />} />
          <Route path='/csr' element={<CsrPage />} />
          <Route path='/event' element={<EventPage />} />
          <Route path='/404' element={<ErrorPage />} />
        </Routes>
      </BrowserRouter>

    </div>
  );
}

export default AllRoute;
