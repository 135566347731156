import React from "react";
import blogs from '../../api/blogs'
import { Link } from "react-router-dom";
import projMan from '../../images/projSub.jpg'
import studentReq from '../../images/studentReq.jpg'
import secretary from '../../images/secretary.jpg'


const ClickHandler = () => {
    window.scrollTo(10, 0);
}

const CareerSection = () => {
    return (
        <section className="blog-section section-padding pt-5">
            <div className="container">
                <div className="row">
                    <div className="col col-lg-6 col-12">
                        <div className="section-title-s2">
                            <span>Employment</span>
                            <h2> Employment Opportunities</h2>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col col-xs-12">
                        <div className="blog-grids clearfix">
                                <div className="grid">
                                    <div className="entry-media">
                                        <img src={studentReq} alt="" />
                                    </div>
                                    <div className="entry-date">
                                        <h4>28</h4>
                                        <span>Jan <br/>2025</span>
                                    </div>
                                    <h3><Link to="/career/Student-Recruitment">International Student Recruitment Officer(Admissions Officer)</Link></h3>
                                </div>

                                <div className="grid">
                                    <div className="entry-media">
                                        <img src={projMan} alt="" />
                                    </div>
                                    <div className="entry-date">
                                        <h4>24</h4>
                                        <span>Jan <br/>2025</span>
                                    </div>
                                    <h3><Link to="/career/Project-Management">Construction Project Manager</Link></h3>
                                </div>

                                <div className="grid">
                                    <div className="entry-media">
                                        <img src={secretary} alt="" />
                                    </div>
                                    <div className="entry-date">
                                        <h4>14</h4>
                                        <span>Mar <br/>2025</span>
                                    </div>
                                    <h3><Link to="/career/Secretary">Secretary (Admissions & Customer Support)
                                    </Link></h3>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default CareerSection;